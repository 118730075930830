import { useCallback, useContext, useEffect, useState, useRef } from 'react'
import { useRoute, useNavigation, useFocusEffect, CommonActions } from '@react-navigation/native'
import { StyleSheet } from 'react-native'
import { FlatListWithRefresh } from '../../molucules/FlatListWithRefresh'
import isEqual from 'lodash.isequal'
import ThumbsUp from '../../molucules/ThumbsUp'
import Box from '../../../components/atoms/Box'
import PlanCard from '../../molucules/PlanCard'
import PlansTopSection from '../../molucules/PlansTopSection'
import colors from '../../tokens/colors'
import {
  PLANS,
  BOTTOM_NAVIGATOR,
  PROFILE_TAB,
  PLANS_QUERY_PARAMS,
  ContractOverviewItem,
} from '../../../types'
import { getContracts } from '../../../endpoints'
import { useFetch, RequestStatus } from '../../../hooks/useFetch'
import { AuthContext } from '../../../providers/AuthProvider'
import { ContentContext } from '../../../providers/ContentProvider'
import { ContractContext } from '../../../providers/ContractProvider'
import GenericModal from '../../molucules/GenericModal'
import { NoPlansMessage } from '../../molucules/NoPlansMessage'
import Loading from '../../../components/molucules/Loading'
import { DimensionContext } from '../../../providers'
import { biometricsSetupFlagExists, setBiometricsSetupFlagValue } from '../../../helpers/biometrics'
import { useCheckBiometricsCapable } from '../../../hooks/useCheckBiometricsCapable'
import { Platform } from 'react-native'

type PlansType = ContractOverviewItem[]

const PlansOverview = () => {
  const navigation = useNavigation()
  const route = useRoute()
  const entryModalType = route?.params?.paramType
  const entryModalTypeBoolean = entryModalType ? true : false
  const [showModal, setShowModal] = useState(entryModalTypeBoolean)
  const [showBiometricsModal, setShowBiometricsModal] = useState<boolean | false>(false)
  const [hasUsedBiometrics, sethasUsedBiometrics] = useState<boolean | false>(false)
  const { isLoggedIn } = useContext(AuthContext)
  const [plans, setPlans] = useState<PlansType>([])
  const [totalBalanceValue, setTotalBalanceValue] = useState(0)
  const [isLoading, setLoading] = useState(true)
  const [url, setUrl] = useState('')
  const [isRefreshing, setRefreshing] = useState(false)
  const isFocused = useRef(true)
  const { viewPort } = useContext(DimensionContext)
  const isMobile = viewPort === 'xs' || viewPort === 'sm'
  const { localized } = useContext(ContentContext)
  const { setHasPaymentProblem } = useContext(ContractContext)

  const { status, data } = useFetch({
    url,
    method: 'get',
  })

  const handleRefresh = () => {
    if (!isRefreshing && isLoggedIn) {
      setRefreshing(true)
      setUrl(`${getContracts}${Platform.OS == 'web' ? '?isWeb=true' : ''}`)
    }
  }

  const isBiometricsAvailable = useCheckBiometricsCapable()

  const checkBiometricsModal = async () => {
    const userHasUsedBiometrics = await biometricsSetupFlagExists()
    sethasUsedBiometrics(userHasUsedBiometrics)
    setShowBiometricsModal(
      !entryModalTypeBoolean && !userHasUsedBiometrics && isBiometricsAvailable
    )
  }

  useEffect(() => {
    const hasProblem = plans.some(plan => plan.paymentProblem && plan.updateEFTStatus !== 'pending')
    setHasPaymentProblem(hasProblem)
  }, [plans])

  useEffect(() => {
    if (isBiometricsAvailable) {
      checkBiometricsModal()
    }
  }, [isBiometricsAvailable])

  useEffect(() => {
    if (status === RequestStatus.FETCHING && isRefreshing) return
    if (status === RequestStatus.FETCHING) {
      setLoading(true)
      return
    }
    setLoading(false)
    if (status === RequestStatus.FETCHED && data && url) {
      const { items, totalBalance } = data
      const plansArray = Array.isArray(items) ? items : []
      const totalBalanceNumber = isNaN(totalBalance) ? 0 : totalBalance
      if (!isEqual(plansArray, plans)) {
        setPlans(plansArray)
      }
      if (totalBalanceNumber !== totalBalanceValue) {
        setTotalBalanceValue(totalBalanceNumber)
      }
      setUrl('')
    }
  }, [data, status, isRefreshing, url])

  useEffect(() => {
    if (status === RequestStatus.FETCHED) {
      setRefreshing(false)
    }
  }, [status])

  useFocusEffect(
    useCallback(() => {
      if (isLoggedIn) {
        setUrl(`${getContracts}${Platform.OS == 'web' ? '?isWeb=true' : ''}`)
        isFocused.current = true
      }

      return () => {
        setUrl('')
        isFocused.current = false
      }
    }, [isLoggedIn])
  )

  const closeModal = () => {
    setShowModal(false)
    navigation.navigate(BOTTOM_NAVIGATOR, {
      screen: PLANS,
      params: {
        screen: PLANS,
        params: { welcome: undefined },
      },
    })
  }

  const closeSetupBiometricsModal = () => {
    setShowBiometricsModal(false)
    navigation.navigate(BOTTOM_NAVIGATOR, {
      screen: PLANS,
      params: {
        screen: PLANS,
        params: { welcome: undefined },
      },
    })
  }

  const dontShowSetupModalAgain = () => {
    setShowBiometricsModal(false)
    if (!hasUsedBiometrics) {
      setBiometricsSetupFlagValue(true)
    }
  }

  const navigateToBiometricsSetup = () => {
    setShowModal(false)
    setShowBiometricsModal(false)

    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [
          {
            name: BOTTOM_NAVIGATOR,
            params: {
              screen: PROFILE_TAB,
            },
          },
        ],
      })
    )
  }

  const plansUI = () => {
    if (!isLoggedIn) return <NoPlansMessage showImage={!isMobile} />

    const { problem, rest } = plans.reduce(
      (acc, plan) => {
        const { paymentProblem, updateEftStatus } = plan
        if (paymentProblem && updateEftStatus !== 'pending') {
          acc.problem.push(plan)
        } else {
          acc.rest.push(plan)
        }
        return acc
      },
      {
        problem: [],
        rest: [],
      }
    )
    const sortedPlansArray = [...problem, ...rest]

    return plans.length ? (
      <Box backgroundLight={colors['--background-light']} style={{ flex: 1 }}>
        <Box
          paddingTop={[16, 16, 24, 24, 24]}
          paddingLeft={[16, 16, 80, 80, 80]}
          paddingRight={[16, 16, 0, 0, 0]}
          style={{ flex: 1 }}>
          <FlatListWithRefresh
            data={sortedPlansArray}
            renderItem={({ item }) => <PlanCard {...item} />}
            refreshing={isRefreshing}
            onRefresh={handleRefresh}
            keyExtractor={plan => `${plan.contractKey}`}
          />
        </Box>
      </Box>
    ) : (
      <NoPlansMessage showImage={!isMobile} />
    )
  }

  const { WELCOME, PHONE_UPDATED, CONTRACT_NOT_FOUND } = PLANS_QUERY_PARAMS
  const getEntryModalContent = (modalType: string) =>
    ({
      [PHONE_UPDATED]: {
        header: 'plans.accountUpdatedHeader',
        body: 'plans.accountUpdatedMessage',
      },
      [WELCOME]: { header: 'plans.allDone', body: 'plans.welcome' },
      [CONTRACT_NOT_FOUND]: {
        header: 'plans.contractNotFoundHeader',
        body: 'plans.contractNotFoundBody',
      },
    }[modalType] || { header: '', body: '' })

  const hasThumbsUpIcon = [WELCOME, PHONE_UPDATED].includes(entryModalType)

  const entryModalProps = {
    closeModal,
    header: getEntryModalContent(entryModalType).header,
    visible: showModal,
    message: getEntryModalContent(entryModalType).body,
    buttonText: 'global.continue',
    ...(hasThumbsUpIcon && { headerIcon: () => <ThumbsUp /> }),
    ...(isBiometricsAvailable && {
      secondaryButtonText: localized('biometricsSetup.setup'),
      onSecondaryButtonPress: navigateToBiometricsSetup,
    }),
  }

  const entryBiometricModalProps = {
    header: 'biometricsSetup.heading',
    visible: showBiometricsModal,
    closeModal: closeSetupBiometricsModal,
    message: 'biometricsSetup.body',
    buttonText: 'biometricsSetup.button',
    onButtonPress: navigateToBiometricsSetup,
    secondaryButtonText: 'biometricsSetup.buttonSecondary',
    onSecondaryButtonPress: dontShowSetupModalAgain,
  }

  const modalProps = showBiometricsModal ? entryBiometricModalProps : entryModalProps

  return (
    <Box style={styles.wrapper} backgroundLight="white">
      <GenericModal {...modalProps} />
      <PlansTopSection totalBalance={totalBalanceValue} />
      {isLoading && !plans.length ? (
        <Loading variant="in-screen" />
      ) : isFocused.current ? (
        plansUI()
      ) : null}
    </Box>
  )
}

export default PlansOverview

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
})
