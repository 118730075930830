import { VFC, useContext, useEffect, useState } from 'react'
import { Platform } from 'react-native'

import {
  InnerCard,
  OuterCard,
  SharedBody,
  CornerIcon,
  StyledButton,
  CornerIconType,
  HeaderBlock,
  StyledLoader,
  InnerCardBlock,
  OtherItemsBlock,
  IsInCollectionsBlock,
  StyledHairline,
  StyledLink,
} from './styled'
import { AprBlockRow } from './AprBlockRow'
import {
  useNavigation,
  useRoute,
  RouteProp,
  useIsFocused,
  useFocusEffect,
  CommonActions,
} from '@react-navigation/native'
import { ThemeContext } from '../../../providers/ThemeProvider'
import { ContentContext } from '../../../providers/ContentProvider'
import { ContractContext, ModalVariant } from '../../../providers/ContractProvider'
import { DimensionContext } from '../../../providers/DimensionProvider'
import Box from '../../../components/atoms/Box'
import Link from '../../atoms/Link'
import MakeAPaymentEntry from '../../organisms/MakeAPaymentEntry'
import PlanCardRow from '../../molucules/PlanCardRow'
import PlanCardRowGridItem from '../../molucules/PlanCardRowGridItem'
import PlanCardDescription from '../../molucules/PlanCardDescription'
import { getContracts, updatePaymentMethod } from '../../../endpoints'
import { useFetch, RequestStatus } from '../../../hooks/useFetch'
import { useFlagSecure } from '../../../hooks/useFlagSecure'
import PlanDetailWrapper from '../../wrappers/PlanDetailWrapper'
import { getPlanConditionals, getEFTModalVariant, getPlanDetailsModalVariant } from './helpers'
import {
  StatusDescription,
  PaymentIcon,
  ApiResponse,
  BOTTOM_NAVIGATOR,
  PLANS,
  PURCHASES_TAB,
  PLANS_QUERY_PARAMS,
  AURORA_INSTALLMENT,
  AURORA_ONE_TIME,
} from '../../../types'

const PlanDetailInformation: VFC = () => {
  const [conditionals, setConditionals] = useState({
    isPerMonth: false,
    isBiWeekly: false,
    isOneTime: false,
    showBalanceRemaining: false,
    showNextPayment: false,
    showDetailsCard: false,
    isRevisedTradeIn: false,
    showMakeAPaymentButton: false,
    showUpdatePaymentMethodLink: false,
    isInCollections: false,
    isPending: false,
  })

  const { contract, setContract, isRefreshing, setRefreshing, eftPayload, setEFTPayload } =
    useContext(ContractContext)
  const { viewPort } = useContext(DimensionContext)
  const { theme } = useContext(ThemeContext)
  const navigation = useNavigation()

  const [isLoading, setLoading] = useState(true)
  const [url, setUrl] = useState('')

  const isFocused = useIsFocused()
  const isMobile = viewPort === 'xs'
  const auroraCardRemoval = conditionals.isRevisedTradeIn
  const { localized, getDate, getCurrency } = useContext(ContentContext)
  const onRefresh = () => setRefreshing(true)
  const onRefreshEnd = () => setRefreshing(false)
  const isNative = Platform.OS === 'android' || Platform.OS === 'ios'

  useFlagSecure()

  type NavParamList = {
    PlanDetail: {
      paymentMethodUpdated?: string
    }
  }
  const route = useRoute<RouteProp<NavParamList, 'PlanDetail'>>()
  const paymentMethodUpdated = route?.params?.paymentMethodUpdated
  const contractKey = route?.params?.id
  const getContractsURL = `${getContracts}/${contractKey}${Platform.OS == 'web' ? '?isWeb=true' : ''}`

  const cornerIconType: CornerIconType = conditionals.isInCollections ? 'failure' : 'success'

  const isGetContract = url === getContractsURL
  const { status, data } = useFetch<ApiResponse>({
    url,
    method: isGetContract ? 'get' : 'patch',
    ...(!isGetContract && { body: eftPayload }),
  })

  useEffect(() => {
    if (eftPayload?.flinksLoginID && eftPayload?.accountId) {
      setUrl(updatePaymentMethod(contract?.contractKey?.toString()))
    }
  }, [eftPayload])

  useFocusEffect(() => {
    if (
      contract.modalVariant &&
      contract.modalVariant !== ModalVariant.MAKE_PAYMENT &&
      Platform.OS === 'web'
    ) {
      navigation.navigate('MakePayment')
    }
  })

  const handleContractDetailResponse = ({ contractDetail }) => {
    const conditionalValues = getPlanConditionals(contractDetail)
    const isApple = [AURORA_INSTALLMENT, AURORA_ONE_TIME, AURORA_ONE_TIME].includes(
      contractDetail.contractType
    )
    setContract({ ...contractDetail, isApple })
    setConditionals(conditionalValues)
  }

  const handleUpdateEFTResponse = ({
    statusDescription,
  }: {
    statusDescription: StatusDescription
  }) => {
    const modalVariant = getEFTModalVariant(statusDescription)
    setEFTPayload(null)
    setContract(contractState => ({
      ...contractState,
      modalVariant,
    }))
    if (Platform.OS === 'web' && isFocused) navigation.navigate('MakePayment')
  }

  useEffect(() => {
    if (status === RequestStatus.FETCHING && isRefreshing) return
    if (status === RequestStatus.FETCHING) {
      setLoading(true)
      setEFTPayload(null)
      return
    }

    if (status === RequestStatus.FETCHED && data) {
      if (isGetContract) {
        handleContractDetailResponse(data)
      } else {
        handleUpdateEFTResponse(data)
      }
      setUrl('')
      setLoading(false)
    }
    if (status === RequestStatus.ERROR) {
      const navToPlansWithParam = (params: { [s: string]: string }) => {
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [
              {
                name: BOTTOM_NAVIGATOR,
                params: {
                  screen: PURCHASES_TAB,
                  params: { params, screen: PLANS },
                },
              },
            ],
          })
        )
      }

      navToPlansWithParam({ paramType: PLANS_QUERY_PARAMS.CONTRACT_NOT_FOUND })
    }
  }, [data, status])

  useEffect(() => {
    if (status === RequestStatus.FETCHED) {
      onRefreshEnd()
    }
  }, [status])

  useEffect(() => {
    if (!getContractsURL) return
    setUrl(getContractsURL)
  }, [getContractsURL])

  useEffect(() => {
    if (isRefreshing) {
      setUrl(getContractsURL)
    }
  }, [isRefreshing])

  useEffect(() => {
    if (paymentMethodUpdated) {
      setContract(contractState => ({
        ...contractState,
        modalVariant: ModalVariant.PAYMENT_METHOD_UPDATED,
      }))
      if (Platform.OS === 'web') navigation.navigate('MakePayment')
    }
  }, [paymentMethodUpdated])

  const handleSchedulePress = () => {

    if (conditionals.isPending && contract.paymentProblem) {
      navigation.navigate('MakePayment')

      setContract(contractState => ({
        ...contractState,
        modalVariant: ModalVariant.CONTRACT_PENDING_SCHEDULE,
      }))
      return
    }

    if (contract.isCaptured) {
      navigation.navigate('Schedule')
    } else {
      if (Platform.OS === 'web') {
        navigation.navigate('MakePayment')
      }

      setContract(contractState => ({
        ...contractState,
        modalVariant: ModalVariant.CONTRACT_NOT_CAPTURED,
      }))
    }
  }

  const handleMakeAPaymentButtonPress = () => {

    setContract(contractState => ({
      ...contractState,
      modalVariant: getPlanDetailsModalVariant(contractState),
    }))
    if (Platform.OS === 'web') navigation.navigate('MakePayment')
  }

  const showUpdatePaymentErrorModal = () => {
    setContract(contractState => ({
      ...contractState,
      modalVariant: ModalVariant.CONTRACT_NOT_CAPTURED,
    }))
    if (Platform.OS === 'web') navigation.navigate('MakePayment')
  }

  const enterUpdatePaymentFlow = () => {
    navigation.navigate('PaymentMethodSelector')
  }

  const handleUpdatePaymentButtonPress = () => {
    const { isCaptured } = contract
    isCaptured ? enterUpdatePaymentFlow() : showUpdatePaymentErrorModal()
  }

  if (isLoading) {
    return <StyledLoader />
  }

  const {
    contractDate,
    contractTitle,
    contractSubtitle,
    contractNumber,
    currentBalance,
    apr,
    paidInFull,
    financedAmount,
    totalRepaymentAmount,
    nextPaymentAmount,
    nextPaymentDate,
    incrementValue,
    remainingTermsToPay,
    tradeInCredit,
    revisedTradeInValue,
    shippingCharges,
    originalTotal,
    applicableTaxes = [],
    otherItems = [],
    paymentAccountNumber,
    paymentIcon,
    paymentProblem,
    totalMonths,
    updateEFTStatus,
  } = contract || {}

  const hasApplicableTaxes = Array.isArray(applicableTaxes) && applicableTaxes.length

  const title = contractSubtitle ? `${contractTitle} ${contractSubtitle}` : contractTitle

  const paymentProblemAndNotPending = paymentProblem && updateEFTStatus !== 'pending'

  return (
    <>
      <PlanDetailWrapper
        title={title}
        {...Platform.select({
          native: { isRefreshing, onRefresh, onRefreshEnd, scrollView: true },
          web: { isModal: !isMobile },
          default: {},
        })}>
        <PlanDetailWrapper.InnerWrapper isMobile={isMobile}>
          <HeaderBlock contract={contractNumber} date={getDate(new Date(contractDate))} />
          <OuterCard theme={theme} level="--elevation-regular-2">
            <InnerCard theme={theme}>
              <CornerIcon
                shouldRender={paidInFull || conditionals.isInCollections}
                type={cornerIconType}
              />
              <InnerCardBlock
                isPIF={paidInFull}
                totalRepayment={getCurrency(totalRepaymentAmount)}
              />
              {conditionals.isPerMonth ? (
                <PlanCardDescription
                  textAtFirstPostion={getCurrency(incrementValue)}
                  textAtSecondPostion={`/${localized('plans.month')} ${localized(
                    'plans.for'
                  )} ${totalMonths} ${localized('plans.months')}`}
                  boldAtFirstPostion={true}
                />
              ) : null}
              {conditionals.isBiWeekly ? (
                <PlanCardDescription
                  textAtFirstPostion={`4 ${localized('plans.biWeeklyPayments')} `}
                  textAtSecondPostion={getCurrency(incrementValue)}
                />
              ) : null}
              {conditionals.isOneTime ? (
                <SharedBody> {localized('plans.oneTimePayment')} </SharedBody>
              ) : typeof apr === 'number' ? (
                <AprBlockRow apr={apr} />
              ) : null}
            </InnerCard>
            <IsInCollectionsBlock
              shouldRender={conditionals.isInCollections}
              heading={localized('plans.collectionsNotice')}
            />
            {conditionals.showBalanceRemaining && (
              <PlanCardRow
                label={localized('plans.balanceRemaining')}
                dollarAmount={getCurrency(currentBalance)}
                description={`(${remainingTermsToPay} ${localized('plans.payment')}${
                  remainingTermsToPay === 1 ? '' : 's'
                })`}
              />
            )}
            {conditionals.showNextPayment && nextPaymentDate && (
              <PlanCardRow
                label={localized('plans.nextPayment')}
                dollarAmount={getCurrency(nextPaymentAmount as number)}
                description={`${localized('plans.nextPaymentJoin')} ${getDate(nextPaymentDate)}`}
              />
            )}
          </OuterCard>
          {conditionals.showDetailsCard && !auroraCardRemoval && (
            <OuterCard theme={theme} level="--elevation-regular-2">
              <Box style={{ paddingHorizontal: 8 }}>
                {conditionals.isRevisedTradeIn ? (
                  <>
                    <PlanCardRowGridItem
                      label={localized('plans.instantTradeInCredit')}
                      dollarAmount={getCurrency(tradeInCredit)}
                    />
                    <PlanCardRowGridItem
                      label={localized('plans.revisedTradeInValue')}
                      dollarAmount={getCurrency(revisedTradeInValue)}
                    />
                  </>
                ) : tradeInCredit > 0 ? (
                  <>
                    <PlanCardRowGridItem
                      label={localized('plans.originalTotal')}
                      dollarAmount={getCurrency(originalTotal)}
                    />
                    <PlanCardRowGridItem
                      label={localized('plans.instantTradeInCredit')}
                      dollarAmount={getCurrency(tradeInCredit)}
                    />
                  </>
                ) : null}
                <OtherItemsBlock
                  otherItems={otherItems}
                  getCurrency={getCurrency}
                  title={localized('plans.products')}
                />
                {hasApplicableTaxes ? (
                  <>
                    <SharedBody style={{ marginVertical: 8 }}>
                      {localized('plans.taxes')}
                    </SharedBody>
                    {applicableTaxes.map(({ itemDescription, taxAmount }) => (
                      <PlanCardRowGridItem
                        key={itemDescription}
                        label={itemDescription}
                        dollarAmount={getCurrency(taxAmount)}
                      />
                    ))}
                  </>
                ) : null}
                {conditionals.isOneTime ? (
                  <>
                    <Box style={{ marginVertical: 8 }}>
                      <PlanCardRowGridItem
                        label={localized('plans.shippingCharges')}
                        dollarAmount={getCurrency(shippingCharges)}
                        variant={'--body-02-semi-emphasized'}
                      />
                    </Box>
                  </>
                ) : null}
                <StyledHairline />
                <PlanCardRowGridItem
                  label={localized(
                    `plans.${
                      tradeInCredit > 0 && !conditionals.isRevisedTradeIn
                        ? 'adjustedTotal'
                        : conditionals.isOneTime
                        ? 'amountPaid'
                        : 'amountOwed'
                    }`
                  )}
                  dollarAmount={getCurrency(financedAmount)}
                />
              </Box>
            </OuterCard>
          )}
          <OuterCard
            theme={theme}
            isError={paymentProblemAndNotPending}
            level="--elevation-regular-2">
            <PlanCardRow
              error={paymentProblemAndNotPending}
              label={
                paymentProblemAndNotPending
                  ? localized('plans.paymentProblem')
                  : localized('plans.paymentMethod')
              }
              paymentAccountNumber={
                conditionals.isPending ? localized('plans.pending') : `••••${paymentAccountNumber}`
              }
              paymentIcon={conditionals.isPending ? PaymentIcon.OTHERBANK : paymentIcon}>

              <Link onPress={handleUpdatePaymentButtonPress}>{localized('plans.update')}</Link>
            </PlanCardRow>
          </OuterCard>
          <StyledButton
            shouldRender={conditionals.showMakeAPaymentButton}
            onPress={handleMakeAPaymentButtonPress}>
            {localized('planDetailInformation.button')}
          </StyledButton>
          <StyledLink onPress={handleSchedulePress}>
            {localized('plans.paymentSchedule')}
          </StyledLink>
        </PlanDetailWrapper.InnerWrapper>
      </PlanDetailWrapper>
      {isNative && <MakeAPaymentEntry />}
    </>
  )
}

export default PlanDetailInformation
