import { DOTNET_API_ROOT } from '../config/api'
import { Platform } from 'react-native'

const API_ROOT = `${DOTNET_API_ROOT}/api/`
const AUTH = `${API_ROOT}authentication/`
const CONTRACTS = `${API_ROOT}contracts`
const ACCOUNT = `${API_ROOT}account`
const PAYMENTS = `${API_ROOT}payments`
const ACCOUNT_UPDATE = `${ACCOUNT}/initiateUpdate`
const PATCH_UPDATE = `${ACCOUNT}/update`

export const postPayment = PAYMENTS
export const getPaymentSchedule = PAYMENTS
export const getContracts = `${CONTRACTS}`
export const logout = `${AUTH}logout`
export const getToken = `${AUTH}getToken`
export const verifyPin = `${AUTH}validateOneTimePin`
export const linkAccount = `${AUTH}portalAccountMatch`
export const refreshToken = `${AUTH}refreshToken`
export const validateEmailPin = `${AUTH}validateEmailPin`
export const patchBiometricKey = `${AUTH}RSACredentials`
export const postBiometricLogin = `${AUTH}BiometricLogin`
export const accountVerification = `${AUTH}AccountVerification`
export const sendVerifcationEmail = `${AUTH}SendVerificationEmail`
export const getCustomers = `${ACCOUNT}/getCustomerDetails`
export const updatePaymentMethod = (contractKey?: string) =>
  `${CONTRACTS}/${contractKey || ''}/PaymentMethod${Platform.OS == 'web' ? '?isWeb=true' : ''}`
export const initiateEmailUpdate = `${ACCOUNT_UPDATE}/email`
export const initiatePhoneNumberUpdate = `${ACCOUNT_UPDATE}/phonenumber`
export const initiateMailingAddressUpdate = `${PATCH_UPDATE}/mailingaddress`
